import { pageSectionTypes } from 'components/ContentHandlers/pageSectionTypes'
import { ChildComponentGenerator } from 'components/ContentHandlers'
import styles from './DebtQuiz.module.scss'
import { Typography } from 'components/Contentful'
import { LinearProgress } from '@achieve/ascend'
import { Image } from 'components/Image'

const QuizResultPage = ({ resultList = [], isMobile }) => {
  return resultList.length > 0 ? (
    resultList.map((section) => {
      const lazyLoad = false
      section.isMobileUA = isMobile
      const element = (
        <ChildComponentGenerator
          contentTypes={pageSectionTypes}
          uiComponent={section?.fields?.uiComponent}
          isCrawler={false}
          lazyLoad={lazyLoad}
          {...section}
          key={section?.sys?.id}
        />
      )
      return element
    })
  ) : (
    <div className={styles['result-section']}>
      <div>
        <Image alt="Calculating your Debt Fit Score™" width="224" height="189" src="/loader.gif" />
      </div>
      <div>
        <Typography
          variant="headingMd"
          className={styles['loader-text']}
          content="Calculating your Debt Fit Score™"
        />
      </div>
      <div className={styles['progress-container']}>
        <div>
          <LinearProgress />
        </div>
      </div>
    </div>
  )
}

export default QuizResultPage
